<template>
  <v-card hover
          :class="{'noclick': noClick}"
          @click="selectItem(item)">
    <v-row v-if="loading"
           justify="center">
      <sb-loader />
    </v-row>

    <v-list-item v-else
                 three-line>
      <v-list-item-content>
        <v-row>
          <v-col class="py-0"
                 cols="4">
            <v-list-item-title class="text-h4 font-weight-bold">
              <v-tooltip right>
                <template #activator="{on}">
                  <span v-on="on">
                    {{ item.serviceRequestNumber }}
                  </span>
                </template>
                <span>Fund Allocation Request Number</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.createdTimestamp }}</v-list-item-subtitle>
            <br>
            <v-list-item-title v-if="item.audit.createdBy"
                               class="text-body-1 font-weight-bold">
              <v-icon left>
                mdi-account
              </v-icon>{{ item.audit.createdBy }}
            </v-list-item-title>
            <br>
            <v-list-item-title v-if="item.fundAllocation.batchNumber">
              <v-tooltip top>
                <template #activator="{on}">
                  <div class="text-body-1 font-weight-bold"
                       v-on="on">
                    <v-icon left>
                      mdi-image-multiple
                    </v-icon>
                    Batch Number: {{ item.fundAllocation.batchNumber }}
                  </div>
                </template>
                <span>Batch Number</span>
              </v-tooltip>
            </v-list-item-title>
          </v-col>
          <v-col cols="1">
            <v-divider vertical />
          </v-col>
          <v-col v-if="item.fundAllocation.declineReasons.length > 0">
            <span class="text-subtitle-1 font-weight-bold">Status Reason:</span> <br>
            <v-list-item-content v-for="(reason, actionIndex) in item.fundAllocation.declineReasons"
                                 :key="actionIndex">
              <v-list-item-title class="text-subtitle-2">
                {{ reason.value }}
              </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getFundStatusColor(item.status.value)"
                        v-on="on">
                  {{ item.status.value }}
                </v-chip>
              </template>
              <span>Fund Allocation Status</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="showRefresh"
                 class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-btn class="sb-mt-nn6 ml-0 sb-mr-nn6"
                       fab
                       dark
                       small
                       color="secondary"
                       v-on="on"
                       @click="refresh(item.serviceRequestNumber)">
                  <v-icon dark>
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import { FUND_STATUS_COLORS } from '@/config/constants';

export default {
  components: {},

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  methods: {
    getFundStatusColor(status) {
      return FUND_STATUS_COLORS[status];
    },
    selectItem(item) {
      if (!this.noClick) {
        this.$router.push({
          name: 'FundAllocationsViewFundAllocation',
          params: {
            fundAllocationRequestNumber: item.serviceRequestNumber,
          },
        });
      }
    },
    refresh(serviceRequestNumber) {
      this.$emit('refresh-fund-allocation', serviceRequestNumber);
    },
  },
};
</script>
