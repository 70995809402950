<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete :ref="refName"
                              v-model="selectedValue"
                              :loading="$wait.is('getBillingCentresLoading')"
                              :items="tempBillingCentreResults"
                              :filter="customSearchBillingFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              :disabled="disabled"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="selectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Billing Centre by
                      <strong>Billing hierarchy number</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.hierarchyNumber }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.hierarchyNumber" />
                    <v-list-item-subtitle v-text="item.name" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'BILLING CENTRE',
    },
    billingCentreDefaultValue: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchBillingCentreString: null,
      selectedBillingCentreResult: null,
      billingCentreResults: [],
      tempBillingCentreResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.billingCentreDefaultValue) {
          return this.tempBillingCentreResults.find((data) => data.hierarchyNumber === this.billingCentreDefaultValue);
        }
        return this.selectedBillingCentreResult;
      },
      set(newValue) {
        this.selectedBillingCentreResult = newValue;
      },
    },
  },

  watch: {
    clientId: {
      handler(value) {
        this.searchBillingCentreString = value;
      },
      immediate: true,
    },
    searchBillingCentreString: {
      async handler(value) {
        if (value && value !== this.selectedBillingCentreResult) {
          await this.getBillingCentresByClientId(value);
        }
        if (value === undefined && this.billingCentreDefaultValue === undefined) {
          this.$refs[this.refName].cachedItems = [];
          this.selectedValue = null;
        }
      },
      immediate: true,
    },
    billingCentreDefaultValue: {
      async handler(value) {
        if (value) {
          this.searchBillingCentreString = this.clientId;
        }
      },
      immediate: true,
    },
  },

  methods: {
    // folder name and map action
    ...mapActions('billingCentres', ['getBillingCentres']),
    ...mapWaitingActions('billingCentres', {
      getBillingCentres: 'getBillingCentresLoading',
    }),
    customSearchBillingFilter(item, queryText) {
      const billingCentreId = item.hierarchyNumber ? item.hierarchyNumber.toLowerCase() : '';
      const billingCentreName = item.name ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return billingCentreId.includes(searchText) || billingCentreName.includes(searchText);
    },

    async selectedChange() {
      this.$emit('billing-centre', this.selectedBillingCentreResult);
    },

    async getBillingCentresByClientId(value) {
      this.billingCentreResults = await this.getBillingCentres(value);
      if (this.billingCentreResults.constructor.name === 'Array') {
        this.tempBillingCentreResults = this.billingCentreResults;
      } else {
        this.tempBillingCentreResults = [];
      }
    },
  },
};
</script>
