<template>
  <sb-card title="Supporting Documents"
           min-width="100%">
    <sb-document-list v-if="showDocumentList"
                      :attachments="attachments"
                      :is-loading="isLoading"
                      :is-downloading="isDownloading"
                      @download-document="downloadDocument" />
    <v-container v-else>
      <v-row>
        <v-col cols="12"
               sm="6">
          <sb-file-upload-field v-model="document.oemRequest"
                                rules="required|document"
                                placeholder="Original Client Request"
                                :show-file-counter="true"
                                :is-multiple-file-selection="isMultipleFileSelection"
                                :file-types="fileTypes"
                                label="Original Client Request" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-file-upload-field v-model="document.proofOfBankAccount"
                                placeholder="Proof of Bank Account"
                                rules="document"
                                :file-types="fileTypes"
                                :show-file-counter="true"
                                :is-multiple-file-selection="isMultipleFileSelection"
                                label="Proof of Bank Account" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-file-upload-field v-model="document.authorisationLetter"
                                placeholder="Authorisation Letter"
                                rules="document"
                                :file-types="fileTypes"
                                :show-file-counter="true"
                                :is-multiple-file-selection="isMultipleFileSelection"
                                label="Authorisation Letter" />
        </v-col>
        <v-col v-if="showFundPayoutFields"
               cols="12"
               sm="6">
          <sb-file-upload-field v-model="document.bolPayoutRequest"
                                placeholder="BOL Payout Request"
                                rules="document"
                                :file-types="fileTypes"
                                :show-file-counter="true"
                                :is-multiple-file-selection="isMultipleFileSelection"
                                label="BOL Payout Request" />
        </v-col>
        <v-col v-if="showFundPayoutFields"
               cols="12"
               sm="6">
          <sb-file-upload-field v-model="document.proofOfPayment"
                                placeholder="Proof of Payment"
                                rules="document"
                                :file-types="fileTypes"
                                :show-file-counter="true"
                                :is-multiple-file-selection="isMultipleFileSelection"
                                label="Proof of Payment" />
        </v-col>
      </v-row>
    </v-container>
  </sb-card>
</template>
<script>
import { extend } from 'vee-validate';
import SbDocumentList from '@/components/core/SbDocumentList';

extend('document', {
  validate(value) {
    return !value.some((file) => file.size > 5_242_880);
  },
  message: 'Document size should be less than 5 MB!',
});
export default {
  components: {
    SbDocumentList,
  },
  props: {
    showFundPayoutFields: {
      type: Boolean,
      default: false,
    },
    isMultipleFileSelection: {
      type: Boolean,
      default: false,
    },
    documentEntity: {
      type: Object,
      default: () => {},
    },
    showDocumentList: {
      type: Boolean,
      default: false,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileTypes: 'image/*,.pdf',
      document: this.documentEntity,
    };
  },
  methods: {
    downloadDocument(attachmentId) {
      this.$emit('download-document', attachmentId);
    },
  },
};
</script>
