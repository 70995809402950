<template>
  <v-row>
    <v-col cols="12">
      <sb-client-search rules="selected|required"
                        :client-default-value="journal.clientJournal.clientNumber"
                        @client="selectedFromClient" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-billing-center-search :billing-centre-default-value="journal.clientJournal.billingCentre"
                                :client-id="journal.clientJournal.clientNumber"
                                ref-name="fromBillingCentre"
                                @billing-centre="fromBillingCentre" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-transaction-code-search rules="selected|required"
                                  :transaction-code-default-value="journal.clientJournal.transactionCode"
                                  @transaction="selectedTransactionCode" />
    </v-col>
    <v-col cols="12">
      <sb-text-field v-if="showGLAccount"
                     v-model="journal.clientJournal.glAccountNumber"
                     rules="required"
                     label="GL Account" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-currency-field v-model="journal.clientJournal.transferAmount"
                         :allow-negative="true"
                         label=" Transfer amount"
                         hide-details
                         rules="required" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-select v-model="journal.clientJournal.vatIndicator"
                 :items="vatIndicators"
                 label="VAT Indicator"
                 item-value="key"
                 item-text="value"
                 rules="selected|required" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-text-field v-model="journal.narration"
                     label="Narration"
                     rules="required|narration" />
    </v-col>
    <v-col cols="12"
           sm="6"
           md="6">
      <sb-date-picker-field v-model="journal.journalDate"
                            disabled
                            label="Date"
                            ref-name="date"
                            rules="required" />
    </v-col>
    <v-col cols="12">
      <sb-text-field v-model="journal.refReg"
                     label="Ref/Reg"
                     rules="required|refReg" />
    </v-col>
  </v-row>
</template>
<script>
import { extend } from 'vee-validate';
import SbClientSearch from '@/components/client/SbClientSearch';
import SbBillingCenterSearch from '@/components/client/SbBillingCenterSearch';
import SbTransactionCodeSearch from '@/components/client/SbTransactionCodeSearch';
import { VAT_INDICATORS, JOURNAL_TRANSACTION_CODE } from '@/config/constants';

extend('narration', {
  validate(v) {
    const value = v.replace(/\s+/g, '');
    return value.length <= 12;
  },
  message: (field) => `Only 12 characters are allowed for ${field}`,
});

extend('refReg', {
  validate(v) {
    const value = v.replace(/\s+/g, '');
    return value.length <= 9;
  },
  message: (field) => `Only 9 characters are allowed for ${field}`,
});

export default {
  components: {
    SbClientSearch,
    SbBillingCenterSearch,
    SbTransactionCodeSearch,
  },
  props: {
    journalEntity: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      journal: this.journalEntity,
      showGLAccount: false,
      vatIndicators: VAT_INDICATORS,
    };
  },
  watch: {
    isEdit: {
      handler(value) {
        if (value) {
          this.showGLAccountValue();
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectedFromClient(selectedClient) {
      if (selectedClient) {
        this.journal.clientJournal.clientNumber = selectedClient.id;
        this.billingCenterDisabled = false;
        // map to fundJournal client number and billing centre for display purposes
        this.journal.fundJournal.from.clientNumber = selectedClient.id;
      } else {
        this.journal.clientJournal.clientNumber = undefined;
        this.journal.clientJournal.billingCentre = undefined;
        this.journal.fundJournal.from.clientNumber = undefined;
      }
    },
    fromBillingCentre(selectedBillingCentre) {
      if (selectedBillingCentre) {
        this.journal.clientJournal.billingCentre = selectedBillingCentre.hierarchyNumber;
        // map to fundJournal client number and billing centre for display purposes
        this.journal.fundJournal.from.billingCentre = selectedBillingCentre.hierarchyNumber;
      } else {
        this.journal.clientJournal.billingCentre = undefined;
        this.journal.fundJournal.from.billingCentre = undefined;
      }
    },
    selectedTransactionCode(results) {
      if (results) {
        this.journal.clientJournal.glAccountNumber = results.glAccountNumber;
        this.journal.clientJournal.transactionCode = results.transactionCodeNumber;
        this.showGLAccountValue();
      } else {
        this.journal.clientJournal.glAccountNumber = undefined;
        this.journal.clientJournal.transactionCode = undefined;
        this.showGLAccount = false;
      }
    },
    showGLAccountValue() {
      this.showGLAccount = !!JOURNAL_TRANSACTION_CODE.includes(this.journal.clientJournal.transactionCode);
    },
  },
};
</script>
