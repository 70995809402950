<template>
  <ValidationObserver ref="obs"
                      v-slot="{invalid}">
    <v-row justify="center">
      <v-dialog v-model="dialog"
                persistent
                scrollable
                max-width="700px">
        <v-card>
          <v-toolbar color="#0033a1 !important"
                     dark>
            <v-toolbar-title>Add Transaction</v-toolbar-title>
            <v-spacer />
            <v-btn icon
                   dark
                   left
                   @click="resetForm">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <sb-select v-model="selectedJournalEntityType"
                         :items="journalEntityTypes"
                         :readonly="readonly"
                         label="Journal Entity Type "
                         item-value="key"
                         item-text="value"
                         rules="selected|required"
                         @change="journalEntityTypeChanged" />

              <sb-journal-entity-type-f v-if="ShowJournalF"
                                        :journal-entity.sync="journal" />
              <sb-journal-entity-type-c v-if="ShowJournalC"
                                        :journal-entity.sync="journal"
                                        :is-edit="readonly" />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="secondary"
                   depressed
                   @click="resetForm">
              Cancel
            </v-btn>
            <v-btn color="secondary"
                   depressed
                   :disabled="$wait.any || invalid"
                   @click="addJournal">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </validationobserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import SbJournalEntityTypeF from '@/components/fundAllocation/SbJournalEntityTypeF';
import SbJournalEntityTypeC from '@/components/fundAllocation/SbJournalEntityTypeC';
import Journal from '@/models/fundAllocation/Journal';
import { cleanValueNumberString } from '@/utils';
import { JOURNAL_ENTITY_TYPE, TYPE_OF_JOURNAL } from '@/config/constants';
import moment from 'moment';

export default {
  components: {
    ValidationObserver,
    SbJournalEntityTypeF,
    SbJournalEntityTypeC,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    journalEntity: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      journalEntityTypes: [JOURNAL_ENTITY_TYPE.TYPE_F, JOURNAL_ENTITY_TYPE.TYPE_C],
      ShowJournalF: false,
      ShowJournalC: false,
      journal: new Journal(),
      cleanValueNumberString,
      readonly: false,
      currentDate: moment().format('YYYY-MM-DD'),
      selectedJournalEntityType: null,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.selectedJournalEntityType = null;
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    journalEntity: {
      handler(value) {
        if (value.journalEntityType !== undefined) {
          if (value.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key) {
            this.ShowJournalC = true;
            this.journal.clientJournal.vatIndicator = this.journal.clientJournal.vatIndicator ? this.journal.clientJournal.vatIndicator : 'E';
          } else {
            this.ShowJournalF = true;
          }
          this.journal = this.journalEntity;
          this.journal.journalDate = this.currentDate;
          this.readonly = true;
          this.selectedJournalEntityType = this.journal.journalEntityType.key;
        } else {
          this.journal.journalDate = this.currentDate;
          this.readonly = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    journalEntityTypeChanged(value) {
      if (value === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
        this.ShowJournalF = !this.ShowJournalF;
        this.ShowJournalC = false;
      } else {
        this.ShowJournalF = false;
        this.ShowJournalC = !this.ShowJournalC;
        this.journal.clientJournal.vatIndicator = 'E';
      }
      this.journal.typeOfJournal = null;
    },

    resetForm() {
      this.ShowJournalF = false;
      this.ShowJournalC = false;
      this.dialog = false;
      this.journal = new Journal();
    },

    addJournal() {
      if (this.selectedJournalEntityType === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
        this.journal.fundJournal.typeOfJournal = TYPE_OF_JOURNAL;
        this.journal.journalEntityType = JOURNAL_ENTITY_TYPE.TYPE_F;
      } else {
        this.journal.journalEntityType = JOURNAL_ENTITY_TYPE.TYPE_C;
      }
      this.journal.clientJournal.transferAmount = cleanValueNumberString(this.journal.clientJournal.transferAmount);
      this.journal.fundJournal.incomeAmount = cleanValueNumberString(this.journal.fundJournal.incomeAmount);
      this.journal.fundJournal.expensesAmount = cleanValueNumberString(this.journal.fundJournal.expensesAmount);
      this.journal.fundJournal.interestAmount = cleanValueNumberString(this.journal.fundJournal.interestAmount);
      this.$emit('journal-item', this.journal);
      this.resetForm();
    },
  },
};
</script>
