<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <sb-input label="Type Of Transfer" />
        <ValidationProvider v-slot="{errors}"
                            name="Type Of Transfer"
                            rules="required">
          <v-radio-group v-model="selectedTypeOfTransfer"
                         row>
            <v-radio v-for="transferType in typeOfTransfers"
                     :key="transferType.key"
                     :label="transferType.value"
                     :value="transferType.key"
                     :error-messages="errors" />
          </v-radio-group>
          <span class="field-details"
                :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <sb-input label="From:" />
      </v-col>
      <v-col cols="12">
        <sb-client-search rules="selected|required"
                          :client-default-value="journal.fundJournal.from.clientNumber"
                          @client="selectedFromClient" />
      </v-col>
      <v-col cols="12"
             md="6"
             sm="4">
        <sb-billing-center-search :billing-centre-default-value="journal.fundJournal.from.billingCentre"
                                  :client-id="journal.fundJournal.from.clientNumber"
                                  :rules="showAllToSectionFields ? 'required' : ''"
                                  ref-name="fromBillingCentre"
                                  @billing-centre="fromBillingCentre" />
      </v-col>
      <v-col cols="12"
             md="6"
             sm="4">
        <sb-client-vehicle-search :vehicle-default-value="journal.fundJournal.from.vehicleNumber"
                                  :client-id="journal.fundJournal.from.clientNumber"
                                  :billing-centre-id="fromBillingCentreId"
                                  ref-name="fromVehicle"
                                  rules="required"
                                  @vehicle="fromVehicle" />
      </v-col>
      <v-col cols="12">
        <sb-text-field v-model="journal.fundJournal.from.fundNumber"
                       disabled
                       label="Fund Number"
                       rules="required" />
      </v-col>
      <v-col cols="12"
             sm="6"
             md="4">
        <sb-currency-field v-model="journal.fundJournal.incomeAmount"
                           :allow-negative="true"
                           label="Income"
                           hide-details />
      </v-col>
      <v-col cols="12"
             sm="6"
             md="4">
        <sb-currency-field v-model="journal.fundJournal.expensesAmount"
                           :allow-negative="true"
                           label="Expenses"
                           hide-details />
      </v-col>
      <v-col cols="12"
             sm="6"
             md="4">
        <sb-currency-field v-model="journal.fundJournal.interestAmount"
                           :allow-negative="true"
                           label="Interest"
                           hide-details />
      </v-col>
      <v-col cols="12">
        <v-progress-linear v-show="$wait.is('getClientVehicleFundsLoading')"
                           indeterminate />
      </v-col>
      <v-col v-if="showBalances"
             cols="12">
        <v-divider />
      </v-col>
      <v-col v-if="showBalances"
             cols="12"
             sm="6"
             md="4">
        <span class="text-h5 text-uppercase"
              :class="{'negative-number': setNegativeNumber(avaliableFunds.income)}">{{ avaliableFunds.income | currency }}</span>
      </v-col>
      <v-col v-if="showBalances"
             cols="12"
             sm="6"
             md="4">
        <span class="text-h5 text-uppercase"
              :class="{'negative-number': setNegativeNumber(avaliableFunds.expenses)}">{{ avaliableFunds.expenses | currency }}</span>
      </v-col>
      <v-col v-if="showBalances"
             cols="12"
             sm="6"
             md="4">
        <span class="text-h5 text-uppercase"
              :class="{'negative-number': setNegativeNumber(avaliableFunds.interest)}">{{ avaliableFunds.interest | currency }}</span>
      </v-col>
      <v-col v-if="showBalances"
             cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <sb-input label="
                     To:" />
      </v-col>
      <v-col cols="12">
        <sb-client-search rules="required"
                          :client-default-value="journal.fundJournal.to.clientNumber"
                          @client="selectedToClient" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             sm="4"
             :md="showAllToSectionFields ? '6' : '12'">
        <sb-billing-center-search :billing-centre-default-value="journal.fundJournal.to.billingCentre"
                                  :client-id="journal.fundJournal.to.clientNumber"
                                  :rules="showAllToSectionFields ? 'required' : ''"
                                  ref-name="toBillingCentre"
                                  @billing-centre="toBillingCentre" />
      </v-col>
      <v-col v-if="showAllToSectionFields"
             cols="12"
             md="6"
             sm="4">
        <sb-client-vehicle-search :vehicle-default-value="journal.fundJournal.to.vehicleNumber"
                                  :client-id="journal.fundJournal.to.clientNumber"
                                  :billing-centre-id="toBillingCentreId"
                                  :rules="{required:true, selected:true, isFromVehicleEqualsToVehicle: [this.journal.fundJournal.from.vehicleNumber]}"
                                  ref-name="toVehicle"
                                  @vehicle="toVehicle" />
      </v-col>
      <v-col v-if="showAllToSectionFields"
             cols="12">
        <sb-text-field v-model="journal.fundJournal.to.fundNumber"
                       label="Fund Number"
                       disabled
                       rules="required" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             sm="6"
             md="6">
        <sb-text-field v-model="journal.narration"
                       label="Narration"
                       rules="required|narration" />
      </v-col>
      <v-col cols="12"
             sm="6"
             md="6">
        <sb-date-picker-field v-model="journal.journalDate"
                              disabled
                              label="Date"
                              ref-name="date"
                              rules="required" />
      </v-col>
      <v-col cols="12">
        <sb-text-field v-model="journal.refReg"
                       label="Ref/Reg"
                       rules="required|refReg" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ValidationProvider, extend } from 'vee-validate';
import SbClientSearch from '@/components/client/SbClientSearch';
import SbBillingCenterSearch from '@/components/client/SbBillingCenterSearch';
import SbClientVehicleSearch from '@/components/client/SbClientVehicleSearch';
import {
  JOURNAL_TYPE_OF_TRANSFER,
  JOURNAL_ENTITY_TYPE,
} from '@/config/constants';
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

extend('isFromVehicleEqualsToVehicle', {
  validate(value, [fromVehicleNumber]) {
    return value.vehicleNumber !== fromVehicleNumber;
  },
  message: 'From Vehicle can not be the same as To Vehicle',
});

extend('narration', {
  validate(v) {
    const value = v.replace(/\s+/g, '');
    return value.length <= 12;
  },
  message: (field) => `Only 12 characters are allowed for ${field}`,
});

extend('refReg', {
  validate(v) {
    const value = v.replace(/\s+/g, '');
    return value.length <= 9;
  },
  message: (field) => `Only 9 characters are allowed for ${field}`,
});

export default {
  components: {
    ValidationProvider,
    SbClientSearch,
    SbBillingCenterSearch,
    SbClientVehicleSearch,
  },
  props: {
    journalEntity: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      journal: this.journalEntity,
      showAllToSectionFields: false,
      toBillingCentreId: undefined,
      fromBillingCentreId: undefined,
      typeOfTransfers: [
        JOURNAL_TYPE_OF_TRANSFER.VEHICLE,
        JOURNAL_TYPE_OF_TRANSFER.FUNDCUSTOMER,
      ],
      selectedTypeOfTransfer: null,
      showBalances: false,
      avaliableFunds: {},
    };
  },
  watch: {
    selectedTypeOfTransfer: {
      handler(value) {
        if (value !== null) {
          if (value === '1') {
            this.showAllToSectionFields = true;
            this.journal.fundJournal.typeOfTransfer =
              JOURNAL_TYPE_OF_TRANSFER.VEHICLE;
          } else {
            this.showAllToSectionFields = false;
            this.journal.fundJournal.to.fundNumber = undefined;
            this.journal.fundJournal.typeOfTransfer =
              JOURNAL_TYPE_OF_TRANSFER.FUNDCUSTOMER;
          }
        }
      },
      immediate: true,
    },
    journalEntity: {
      async handler(value) {
        if (value.journalEntityType.key !== undefined) {
          this.selectedTypeOfTransfer = value.fundJournal.typeOfTransfer.key;
        }
        if (
          this.journal.fundJournal.from.clientNumber &&
          this.journal.fundJournal.from.billingCentre &&
          this.journal.fundJournal.from.fundNumber &&
          this.journal.fundJournal.from.vehicleNumber
        ) {
          await this.getVehicleFunds();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('clients', ['getClientVehicleFunds']),

    ...mapWaitingActions('clients', {
      getClientVehicleFunds: 'getClientVehicleFundsLoading',
    }),
    selectedToClient(selectedClient) {
      if (selectedClient) {
        this.journal.fundJournal.to.clientNumber = selectedClient.id;
      } else {
        this.journal.fundJournal.to.clientNumber = undefined;
        this.journal.fundJournal.to.billingCentre = undefined;
        this.journal.fundJournal.to.vehicleNumber = undefined;
        this.journal.fundJournal.to.fundNumber = undefined;
      }
    },

    selectedFromClient(selectedClient) {
      if (selectedClient) {
        this.journal.fundJournal.from.clientNumber = selectedClient.id;
      } else {
        this.journal.fundJournal.from.clientNumber = undefined;
        this.journal.fundJournal.from.billingCentre = undefined;
        this.journal.fundJournal.from.vehicleNumber = undefined;
        this.journal.fundJournal.from.fundNumber = undefined;
      }
    },

    fromBillingCentre(selectedBillingCentre) {
      if (selectedBillingCentre) {
        this.journal.fundJournal.from.billingCentre =
          selectedBillingCentre.hierarchyNumber;
        this.fromBillingCentreId = selectedBillingCentre.id;
      } else {
        this.fromBillingCentreId = undefined;
        this.journal.fundJournal.from.billingCentre = undefined;
      }
    },

    async fromVehicle(selectedVehicle) {
      if (selectedVehicle) {
        this.journal.fundJournal.from.vehicleNumber =
          selectedVehicle.vehicleNumber;
        this.journal.fundJournal.from.fundNumber = selectedVehicle.fundNumber;
        if (this.journal.fundJournal.from.fundNumber) {
          await this.getVehicleFunds();
        }
      } else {
        this.journal.fundJournal.from.vehicleNumber = undefined;
        this.journal.fundJournal.from.fundNumber = undefined;
        this.avaliableFunds = {};
        this.showBalances = false;
      }
    },

    toBillingCentre(selectedBillingCentre) {
      if (selectedBillingCentre) {
        this.journal.fundJournal.to.billingCentre =
          selectedBillingCentre.hierarchyNumber;
        this.toBillingCentreId = selectedBillingCentre.id;
      } else {
        this.toBillingCentreId = undefined;
        this.journal.fundJournal.to.billingCentre = undefined;
      }
    },

    toVehicle(selectedVehicle) {
      if (selectedVehicle) {
        this.journal.fundJournal.to.vehicleNumber =
          selectedVehicle.vehicleNumber;
        this.journal.fundJournal.to.fundNumber = selectedVehicle.fundNumber;
      } else {
        this.journal.fundJournal.to.vehicleNumber = undefined;
        this.journal.fundJournal.to.fundNumber = undefined;
      }
    },
    setNegativeNumber(value) {
      return value < 0;
    },
    async getVehicleFunds() {
      const payload = {
        requestType: JOURNAL_ENTITY_TYPE.TYPE_F.key,
        clientNumber: this.journal.fundJournal.from.clientNumber,
        billingCentre: this.journal.fundJournal.from.billingCentre,
        fundNumber: this.journal.fundJournal.from.fundNumber,
        vehicleNumber: this.journal.fundJournal.from.vehicleNumber,
      };
      this.avaliableFunds = await this.getClientVehicleFunds(payload);
      if (this.avaliableFunds) this.showBalances = true;
    },
  },
};
</script>
