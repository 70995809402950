var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{'noclick': _vm.noClick},attrs:{"hover":""},on:{"click":function($event){return _vm.selectItem(_vm.item)}}},[(_vm.loading)?_c('v-row',{attrs:{"justify":"center"}},[_c('sb-loader')],1):_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-list-item-title',{staticClass:"text-h4 font-weight-bold"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.item.serviceRequestNumber)+" ")])]}}])},[_c('span',[_vm._v("Fund Allocation Request Number")])])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.createdTimestamp))]),_c('br'),(_vm.item.audit.createdBy)?_c('v-list-item-title',{staticClass:"text-body-1 font-weight-bold"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_vm._v(_vm._s(_vm.item.audit.createdBy)+" ")],1):_vm._e(),_c('br'),(_vm.item.fundAllocation.batchNumber)?_c('v-list-item-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 font-weight-bold"},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-image-multiple ")]),_vm._v(" Batch Number: "+_vm._s(_vm.item.fundAllocation.batchNumber)+" ")],1)]}}],null,false,2031460758)},[_c('span',[_vm._v("Batch Number")])])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),(_vm.item.fundAllocation.declineReasons.length > 0)?_c('v-col',[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("Status Reason:")]),_vm._v(" "),_c('br'),_vm._l((_vm.item.fundAllocation.declineReasons),function(reason,actionIndex){return _c('v-list-item-content',{key:actionIndex},[_c('v-list-item-title',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(reason.value)+" ")])],1)})],2):_vm._e()],1)],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":_vm.getFundStatusColor(_vm.item.status.value)}},on),[_vm._v(" "+_vm._s(_vm.item.status.value)+" ")])]}}])},[_c('span',[_vm._v("Fund Allocation Status")])])],1),(_vm.showRefresh)?_c('v-col',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"sb-mt-nn6 ml-0 sb-mr-nn6",attrs:{"fab":"","dark":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.refresh(_vm.item.serviceRequestNumber)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-refresh ")])],1)]}}],null,false,2637940870)},[_c('span',[_vm._v("Refresh")])])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }