<template>
  <sb-card min-width="100%">
    <template slot="title">
      <v-card-title>
        <sb-title title="Approve or Decline Fund Allocation" />
        <v-spacer />
      </v-card-title>
    </template>

    <template slot="actions">
      <v-spacer />

      <v-btn color="error"
             large
             depressed
             :disabled="$wait.any"
             @click="displayDeclineReasonDialog = true">
        Decline
      </v-btn>

      <v-btn color="success"
             large
             depressed
             class="ml-5"
             :disabled="$wait.any"
             @click="approveFundAllocation">
        Approve
      </v-btn>
    </template>
    <sb-dialog-decline-reason v-if="!$wait.is('referenceListLoading')"
                              :decline-reasons="fundAllocationDeclineReasons"
                              :show-dialog="displayDeclineReasonDialog"
                              @declined="declineFundAllocationRequest"
                              @close="displayDeclineReasonDialog = false" />
  </sb-card>
</template>
<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { REFERENCE_LISTS } from '@/config/constants';
import SbDialogDeclineReason from '@/components/core/SbDialogDeclineReason';

export default {
  components: {
    SbDialogDeclineReason,
  },
  data() {
    return {
      fundAllocationDeclineReasons: [],
      displayDeclineReasonDialog: false,
    };
  },

  async created() {
    this.fundAllocationDeclineReasons = await this.getReferenceList(REFERENCE_LISTS.FUNDALLOCATION_DECLINE_REASONS);
  },

  methods: {
    ...mapActions('referenceLists', ['getReferenceList']),

    ...mapWaitingActions('referenceLists', {
      getReferenceList: 'referenceListLoading',
    }),

    declineFundAllocationRequest(reasons) {
      this.$emit('decline', reasons);
    },
    approveFundAllocation() {
      this.$emit('approve');
    },
  },
};
</script>
